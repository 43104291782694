body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* animation */


.button-zoom {
  transition: transform 0.22s;
}

.button-zoom:hover {
  transform: scale(1.02);
}
.button-zoom:active {
  transform: scale(0.9);
}

.breathing {
  animation: breathing .9s linear infinite alternate;
  }

  .shake {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake .5s;
    /* When the animation is finished, start again */
    /* animation-iteration-count: infinite; */
    animation-iteration-count: 2;
  }




/* animation */
@keyframes breathing {
  0% {
    
    transform: scale(0.96);
  }

  /* 25% {
  
    transform: scale(1);
  }

  60% {
   
    transform: scale(0.9);
  } */

  100% {
 
    transform: scale(1);
  }
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-2px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}



