
/* area title */
.area-title-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
}
.arrow-icon {
    width:24px;
    height: 40px;
    object-fit: contain;
}
.area-title.MuiTypography-root{
    font-size: 40px;
    /* color:#fff; */
    color:#fff;
    font-weight: 900;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
    /* -webkit-text-stroke-width:0.5px; */
    /* -webkit-text-stroke-color:#fff; */
}


.area-content-container, .area-content-container .MuiTypography-root{
    font-size: 21px;
    color:#fff;
    font-weight: 500;
    /* text-shadow: 1px 1px 2px rgba(0,0,0,0.11); */
}





