.tab-container {
  padding: 0 16px;
  font-size: 16px;
  position: relative;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.tab-container::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 67.7%;
  transform: translateY(-50%);
  width: 1.27px;
  background-color: #fff;
}

.tab-selected.tab-container {
  color: #4d626b;
}
/* .tab-selected-dark-city.tab-container {
  color: #01fdff
} */
.tab-selected.tab-container::after {
  background-color: #4d626b;
}
title */
.area-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
}
.arrow-icon {
  width: 24px;
  height: 40px;
  object-fit: contain;
}
.area-title.MuiTypography-root {
  font-size: 40px;
  color: #4c6069;
  font-weight: 900;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  /* -webkit-text-stroke-width:0.5px; */
  /* -webkit-text-stroke-color:#fff; */
}

.area-content-container,
.area-content-container .MuiTypography-root {
  font-size: 21px;
  color: #4c6069;
  font-weight: 500;
  /* text-shadow: 1px 1px 2px rgba(0,0,0,0.11); */
}



/* 多新增一個dark city theme color */


.tab-container-dark-city {
  padding: 0 16px;
  font-size: 16px;
  position: relative;
  /* color: #fff; */
  color:#017a90;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.tab-container-dark-city::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 67.7%;
  transform: translateY(-50%);
  width: 1.27px;
  background-color: #017a90;
  /* background-color: #fff; */

}

.tab-selected-dark-city.tab-container-dark-city {
  color: #5affe1;
}
.tab-selected-dark-city.tab-container-dark-city::after {
  background-color: #5affe1;
}
/* title */
.area-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
}
.arrow-icon {
  width: 24px;
  height: 40px;
  object-fit: contain;
}
.area-title.MuiTypography-root {
  font-size: 40px;
  color: #4c6069;
  font-weight: 900;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  /* -webkit-text-stroke-width:0.5px; */
  /* -webkit-text-stroke-color:#fff; */
}

.area-content-container,
.area-content-container .MuiTypography-root {
  font-size: 21px;
  color: #4c6069;
  font-weight: 500;
  /* text-shadow: 1px 1px 2px rgba(0,0,0,0.11); */
}




